.recent-profile-icon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.profile-date-time{
    font-size: 12px;
}
.invoice-btn{
    font-size: 13px;
    color: #72BC40 !important;
    cursor: pointer;
    text-decoration: none !important;
}
.booking-cost{
    font-size: 14px;
}
.tele-booking-btn{
    background: #e4effd;
    color: #0092D6;
    cursor: auto;
}