.prescription-container{
    height: 100vh;
    background-color: #e9ecef;
}
.prescription-header{
    height: 8vh;
}
.prescription-body-card{
    min-height: 92vh;
}
.prescription-body{
    padding: 0 100px;
}
.prescription-footer{
    bottom: 0;
}
.MuiCardHeader-title{
    font-size: 20px !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
}
.MuiCardHeader-content{
    margin-top: -24px !important;
}
.MuiCardHeader-action{
    margin: auto !important;
}
.profile-icon{
    width: 80px;
    height: 80px;
    border-radius: 50px;
}
.prescription-item{
    min-width: 424px;
    width: 48%;
}

.mt-auto{
    margin: auto ;
}
.icon-border{
    border: 1px solid #3b6dba !important;
    border-radius: 4px !important;
}

.btn-outline-info {
    color: #3b6dba !important;
    border-color: #3b6dba !important;
}
.btn-outline-info:hover {
    color: #fff !important;
    border-color: #3b6dba !important;
    background-color: #3b6dba !important;
}

.MuiButton-containedPrimary{
    background-color: #3b6dba !important;
}
.MuiButton-outlinedPrimary{
    color: #3b6dba !important;
    border: 1px solid rgb(59,109,186) !important;
}

.bg-white {
    background-color: white !important;
}
.h5-next{
    display: flex;
    align-items: flex-end;
}
.c-name{
    min-width: 136px;
}

@media only screen and (max-width: 1087px){
    .prescription-item{
        width: 100%;
        margin: 10px auto !important;
    }
    .prescription-body{
        padding: 0px 150px;
    }
}

@media only screen and (max-width: 750px){
    .prescription-item{
        width: 100%;
        margin: 10px auto !important;
    }
    .prescription-body{
        padding: 0px 50px;
    }
}
