.card-body{
    /* display: flex;
    flex-direction: column !important; */
}

.rating-prescription-btn{
    width: fit-content;
    margin: 0 auto;
    color: #3b6dba !important;
    border: 1px solid #3b6dba !important;  
}

.MuiRating-root{
    color: #3b6dba !important;
    margin: 0 auto !important;
}
.MuiSvgIcon-fontSizeInherit {
    font-size: 40px !important;
}
.MuiButton-containedPrimary.btn-rating{
    background-color: #3b6dba !important;
    font-weight: bold;
}
.MuiButton-outlinedPrimary.rbtn-ratings{
    color: #3b6dba !important;
    border: 1px solid #3b6dba !important;   
    font-weight: bold;
}

.rating-header{
    background: #3b6dba;
    color: white;
    font-weight: bold;
}

.rating-close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}