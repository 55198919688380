.thankyou-container{
    text-align: center;
    height: 100vh;
    background-color: #fff;
}
.thankyou-card{
    box-shadow: 0 0 black !important;
    height: 89%;
}
.body-image{
    height: 43%;
}

.MuiButton-containedPrimary{
    background-color: #3b6dba !important;
}
.MuiButton-outlinedPrimary{
    color: #3b6dba !important;
    border: 1px solid rgb(59,109,186) !important;
}

.btn-outline-success{
    
}
.app-header{
    box-shadow: 1px 1px 2px #b1b1b1 !important;
    padding-bottom: 1px;
}

.thankyou-avatar{
    height: 60px !important;
    width: 60px !important;
}

.v-color{
    color: #183660;
}
.p-color{
    color: #697d98;
}

.MuiButton-containedPrimary{
    background-color: #3b6dba !important;
    font-weight: bold;
}
.MuiButton-outlinedPrimary{
    color: #3b6dba !important;
    border: 1px solid rgb(59,109,186) !important;
    font-weight: bold;
}

a.link{
    text-decoration: none;
}

