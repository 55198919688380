.week-strip{
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-y: hidden;
    max-height: 46px;
    padding-left: 5px;
    padding-right: 7px;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 5px;
}
.mtb-auto{
  margin-top: auto;
  margin-bottom: auto;
}
.arrow-font{
  font-size: 2rem !important;
  cursor: pointer;
}

.week-strip-button{
    cursor: pointer;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.week-strip::-webkit-scrollbar {
    /* display: none; */
    height: 5px;
    color: white !important;
    background: ghostwhite !important;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .week-strip::-webkit-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }