.medication-form{
    min-height: 80vh;
    /* width: 80vh; */
}
.MuiCardHeader-title{
    margin-top: 20px !important;
}
.MuiDialog-paperWidthSm{
    max-width: 80vh !important;
    width: 80% !important;
}
.MuiPopover-paper{
    /* margin-top: 78px ; */
}
.duration-dialog{
    margin: 0 auto;
    width: 80vh;
}
.instruction-dialog{
    margin: 0 auto;
    width: 90vh;
}
.m-auto{
    margin: 0 auto;
}
em{
    /* color: tomato; */
}
.MuiButton-containedPrimary{
    background-color: #3b6dba !important;
}
.MuiButton-outlinedPrimary{
    color: #3b6dba !important;
    border: 1px solid rgb(59,109,186) !important;
}

.medication-header{
    background-color: #3b6dba !important;
    border-radius: 0 !important;
    color: white !important;
}
.MuiTypography-h5{
    font-size: 30px !important;
}

.close-icon{
    font-size: 40px !important;
    color: white !important;
}
.bg-white {
    background-color: white !important;
}