.doctorTrue{
  color: #000;
  background-color: #53c8ff !important;
}
.patientTrue{
  color: #000;
  background-color: rgb(175 255 122) !important;
}
  .doctor-status{
    text-align: center;
  }
.header-icon{
    cursor: pointer;
    font-size: 30px;
}
.menu-items-1{
  min-width: 300px;

}
.userEmail {
  margin: auto 0 auto 10px !important;
}

@media only screen and (max-width: 870px) {
  .applogo {
    padding: 0 !important;
    margin: 0 !important;
    width: 170px !important;
  }
  .MuiIconButton-edgeStart{
    display: none !important;
  }
  .MuiToolbar-regular{
    padding: 0 !important;
  }
}

@media only screen and (max-width: 700px) {
  .applogo {
    /* padding: 0 !important;
    margin: 0 !important;
    width: 132px !important; */
  }
  .appLogoImg {
    height: 65px;
  }
  .currentDateIcon{
    display: none;
  }
  .currentDate{
    display: none;
  }

  .MuiIconButton-edgeStart{
    display: none !important;
  }
  .MuiToolbar-regular{
    padding: 0 !important;
  }
}

.simple-menu{
  height: 600px !important;
}
.doctor-status-btn{
  border-radius: 20px;
  font-size: 14px;
  padding:  20px;
}