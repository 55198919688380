@import "./base.scss";

body {
  font-family: $font-family;
}

.App {
  p {
    margin: 5px;
    font-size: 16px;
  }
}
.headerMainTitle {
  font-size: 20px;
}

.mainBodyPart {
  background: $body-bg;
  height: 80vh;
}
