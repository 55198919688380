
.right-sidebar-menu{
    display: none;
  }
.headColor, .react-calendar__navigation{
  background-color: #afff7a; 
}

.react-calendar__viewContainer{
  background-color: white;
}
.patientBgColor{
  background-color: #f0f9eb !important;
}
.left-sidebar{
    border: none !important;
    width: 20% !important;
  }

  @media only screen and (max-width: 1060px){
    .left-sidebar{
      padding-left: 10px;
    }
  }
  @media only screen and (max-width: 780px){
    .right-sidebar-menu{
      display: block;
    }
    .left-sidebar.disp-none{
      display: none;
    }
    .left-sidebar.disp-block{
      display: block;
    }
    .right-sidebar{
      width: 100%;
    }
    
    .MuiSvgIcon-root.menu-icon{
      font-size: 30px !important;
    }
  }