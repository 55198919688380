
.loader-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    background: #00000052;
}
.loader{
    z-index: 1000;
    margin: auto;
}