.MuiSvgIcon-root{
    font-size: 18px !important;
}
.MuiTypography-body1{
    font-size: 14px !important;
}
a{
    color: #007bff !important;
}

.MuiButton-containedPrimary{
    background-color: #3b6dba !important;
}
.MuiButton-outlinedPrimary{
    color: #3b6dba !important;
    border: 1px solid rgb(59,109,186) !important;
}