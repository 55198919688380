.MuiAccordionDetails-root {
    display: block !important;
}
.root{
    box-shadow: none !important;
    /* border-bottom: 2px solid grey;
    border-radius: 0; */
}
.accordion-hr{
    width: 80%;
    margin-top: 11px;
    background: grey;
    margin-left: 14px;
}
.MuiAccordion-root{
    position: initial !important;
}


::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: ghostwhite; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
    background: ghostwhite; 
}


.MuiButton-containedPrimary{
    background-color: #3b6dba !important;
}
.MuiButton-outlinedPrimary{
    color: #3b6dba !important;
    border: 1px solid rgb(59,109,186) !important;
}